import React, { Component } from 'react';
import Userpage from './userpage';

class PortfolioPage extends Component {
    render() {
        return <Userpage />;
    }
}

export default PortfolioPage;
